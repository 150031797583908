<template>
<div class="setting_main_bg">
  <div class="flexAC">
    <el-input v-model="name"  placeholder="请输入搜索内容" class="contInput"></el-input>
    <el-date-picker
      v-model="time"
      value-format="yyyy-MM-dd HH:mm:ss"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      class="timePicker">
    </el-date-picker>
    <el-button class="settingSearch" @click="search()">搜索</el-button>
    <el-button class="settingReset" @click="reset()">重置</el-button>
  </div>
  <el-table
    stripe
    :data="logList"
    style="width: 100%"
    class="logTable">
    <el-table-column
      label="序号"
      type="index">
    </el-table-column>
    <el-table-column
      label="接口名称"
      prop="apiname">
    </el-table-column>
    <el-table-column
      label="提示信息"
      show-overflow-tooltip
      prop="errormsg">
    </el-table-column>
    <el-table-column
      label="请求类型"
      prop="apitype">
    </el-table-column>
    <el-table-column
      label="请求时间"
      show-overflow-tooltip
      prop="logtime">
    </el-table-column>
    <el-table-column
      label="用户名称"
      prop="username">
    </el-table-column>
    <el-table-column
      label="接口状态"
      prop="returnstate">
      <template slot-scope="scope">
        <span v-if="scope.row.returnstate == 1">成功</span>
        <span v-if="scope.row.returnstate == 0">失败</span>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    @current-change="PageChange"
    :current-page="page"
    :page-sizes="[10]"
    :page-size="size"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    class="settingPage">
  </el-pagination>
</div>
</template>

<script>
import { queryLog } from '@/http/api/settingSystem'
export default {
  name: 'log',
  data () {
    return {
      name: '',
      time: [],
      logList: [],
      page: 1,
      size: 10,
      total: 0,
      startTime: '',
      endTime: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      if (this.time.length !== 0 || this.time !== null) {
        this.startTime = this.time[0]
        this.endTime = this.time[1]
      }
      const params = {
        apicode: '',
        page_index: this.page,
        page_size: this.size,
        name: this.name,
        returnstate: '',
        companyid: JSON.parse(window.localStorage.getItem('loginInfo')).companyid,
        stime: this.startTime,
        etime: this.endTime
      }
      queryLog(params).then(res => {
        if (res.status === '1') {
          this.logList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.logList = []
          this.total = 0
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    search () {
      this.page = 1
      this.init()
    },
    reset () {
      this.page = 1
      this.time = []
      this.name = ''
      this.init()
    }
  }
}
</script>

<style scoped>
.logTable{
  width: 100%;
  margin-top: 20px;
}
</style>
